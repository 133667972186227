<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData"
      :updatable="false"
      :deletable="false"
      titleKey="id"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
    ></WsReadSection>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/user_device";
export default {
  metaInfo() {
    return {
      title: "使用者裝置",
    };
  },
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: [
      "type",
      "is_diy",
      "brand",
      "model_number",
      "serial_number",
      "user",
    ],
    rightFields: ["status"],
    modelData: null,
    showFields: [
      "no",
      "created_at",
      "status",
      "pay_status",
      "products_price",
      "invoice_number",
    ],
  }),

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>